<script>
import Auth from "../../layouts/auth"
import appConfig from "../../../../app.config"
import Swal from "sweetalert2"

import { required, email } from "vuelidate/lib/validators"

/**
 * Login component
 */
export default {
  data () {
    return {
      email: "",
      password: "",
      submitted: false,
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
    };
  },
  components: {
    Auth,
  },
  computed: {
    notification () {
      return this.$store ? this.$store.state.notification : null;
    },
    notificationAutoCloseDuration () {
      return this.$store && this.$store.state.notification ? 5 : 0;
    },
  },
  watch: {
    "$root.system_ready" (_systemReady) {
      if (!_systemReady) {
        return
      }

      if (this.$root.login_status) {
        console.log('=> 引導至後台首頁')
        this.$router.push({ path: '/', params: {} })
      }
    }
  },
  created () {
    // 若是已經登入, 前往後台首頁頁面
    if (this.$root.login_status) {
      console.log('=> 引導至後台首頁')
      this.$router.push({ path: '/', params: {} })
    }
  },
  validations: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
  methods: {
    tryToLogIn () {
      this.submitted = true;

      this.$v.$touch();

      // 是否格式驗證正確
      if (this.$v.$invalid) {
        return;
      }

      let data = {
        "account": this.email,
        "password": this.password,
        "key": this.$root.common.generAtorMoreEng(10)
      }

      this.$root.apis.setMemberLogin(data, this.setLoginSuccess, this.setLoginError)
    },
    async setLoginSuccess (_response) {
      // 切換登入狀態
      this.$root.login_status = await true
      // 重新獲取用戶資訊
      await this.$root.apis.getMemberInfo()
      // 彈出登入成功資訊
      this.$root.common.showSingleNotify('已經登入成功')
      // 前往後台首頁頁面
      this.$router.push({ name: 'MarketingSlot', params: {} })
    },
    setLoginError (_response) {
      this.$root.common.showErrorDialog(_response.body.data)
    }
  },
};
</script>

<template lang="pug">
auth
  .row.justify-content-center
    .col-md-8.col-lg-6.col-xl-5
      .card
        .card-body.p-4
          .text-center.w-75.m-auto
            .auth-logo
              router-link.logo.logo-dark.text-center(to="/")
                span.logo-lg
                  img(src="@/assets/images/logo.png")
              router-link.logo.logo-light.text-center(to="/")
                span.logo-lg
                  img(src="@/assets/images/logo.png")
            p.text-muted.mb-4.mt-3
              | 您好，請輸入您的帳號與密碼
          form(action="#", @submit.prevent="tryToLogIn")
            b-alert.mt-3(
              :variant="notification.type",
              v-if="notification.message",
              :show="notificationAutoCloseDuration",
              dismissible=""
            ) {{ notification.message }}
            b-alert.mt-3(
              variant="danger",
              v-model="isAuthError",
              :show="notificationAutoCloseDuration",
              dismissible=""
            ) {{ authError }}
            .form-group.mb-3
              label(for="emailaddress") 帳號
              input#emailaddress.form-control(
                v-model="email",
                type="email",
                placeholder="請輸入您的帳號",
                :class="{ 'is-invalid': submitted && $v.email.$error }"
              )
              .invalid-feedback(v-if="submitted && $v.email.$error")
                span(v-if="!$v.email.required") 帳號是必須輸入的
                span(v-if="!$v.email.email") 帳號是 Email 格式
            .form-group.mb-3
              label(for="password") 密碼
              .input-group.input-group-merge
                input#password.form-control(
                  v-model="password",
                  type="password",
                  placeholder="請輸入您的密碼",
                  :class="{ 'is-invalid': submitted && $v.password.$error }"
                )
                .invalid-feedback(v-if="submitted && !$v.password.required")
                  | 密碼是必須輸入的
            .form-group.mb-0.text-center
              button.btn.btn-primary.btn-block(type="submit")
                | 登入帳號
        // end card-body
        // end card
        // end row
        // end col
        // end row
</template>


<style lang="sass" scoped>
span.logo-lg img
  max-width: 85%
</style>